import React, { useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { AllTemplatesBody } from '../components/Templates/AllTemplatesBody';

const useStyles = makeStyles((theme) => ({
	formBackground: {
		background: theme.workwaveBlue,
		paddingBottom: '2rem',
		marginTop: '-7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-12rem',
		},
	},

	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		marginTop: '7rem',
		padding: '10rem 0 24rem',
		[theme.breakpoints.down('md')]: {
			padding: '10rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const TemplatesPage = ({ data, location }) => {
	const allTemplates = data.allTemplates.edges[0].node;
	const classes = useStyles();
	const templatesData = data.templates.edges;
	const inputRef = useRef();
	const formRef = useRef(null);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const handleInputScroll = (e) => {
		e.preventDefault();
		inputRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const med = useMediaQuery('(max-width: 960px)');
	const {
		metaTitle,
		metaDescription,
		_rawOverviewBody,
		resourceTitle,
		resources,
		_rawResourceBody,
		hero,
	} = allTemplates;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} rg wave />
			<AllTemplatesBody
				allTemplates={allTemplates}
				templatesData={templatesData}
			/>
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query TemplatesPageQuery {
		allTemplates: allSanityAllTemplate {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					_rawOverviewBody
					sectionTitles
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
				}
			}
		}
		templates: allSanityTemplates(sort: { fields: [title], order: ASC }) {
			edges {
				node {
					_id
					title
					slug {
						current
					}
					faIcon
					templatePdfType {
						type
					}
					templateIndustryType {
						type
					}
					accentColor {
						hexValue
					}
					_rawSynopsis
				}
			}
		}
	}
`;

export default TemplatesPage;
